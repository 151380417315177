import React, {useState, createContext, useContext, ReactNode, useEffect} from "react";
import {connect} from '@puzzel/widget-api-lib/lib/main';
import axios, {axiosNpsWorkersInstance, axiosPuzzelInstance, axiosNpsInstance} from "../Utilities/axios";
import {useSnackbar} from "notistack";
import phone from "phone";
import {LinearProgress} from "@mui/material";
import {Call, Chat} from "../models/puzzel";
import { HubConnectionBuilder } from '@microsoft/signalr';

/**
 * Represents the state of Puzzel.
 */
export type PuzzelState = {
    /**
     * The current state of the call or chat.
     * Can be "idle", "incoming", or "ended".
     */
    state: "idle" | "incoming" | "ended" | null,
    /**
     * The call or chat object.
     */
    object: Call | Chat | null,
    /**
     * The type of the current call or chat.
     */
    type: "call" | "chat" | null,
    /**
     * The formatted number of the current call to e.164 standard.
     */
    formattedNumber: string | null
    /**
     * The queue key of the current call or chat.
     */
    queueKey: string | null
    /**
     * The Session ID of the current call or chat.
     */
    sessionId: string | null
    /**
     * The agent answers of the current call or chat.
     */
    agentAnswers?: string
    /**
     * The agent rating of the current call or chat.
     */
    agentRating?: number
}

export type EmployeeState = {
    /**
     * The User ID of the employee.
     */
    userId: string | null
    /**
     * The email address of the employee.
     */
    email: string | null
    /**
     * The group of the employee.
     */
    group: string | null
}

/**
 * Represents the properties of an employee context.
 */
export type EmployeeContextProps = {
    /**
     * The current state of the employee.
     */
    employeeState: EmployeeState
    /**
     * The current state of Puzzel.
     */
    puzzelState: PuzzelState
    /**
     * The current state of the widget controls.
     */
    widgetControls: WidgetControls
    /**
     * The name of the chat variable, used to fetch e.g. phone number of customer.
     */
    campaignMemberModel?: any
    chatVariableName?: string
    /**
     * The delay before a new call is added as a respondent.
     * e.g. we add all new calls as respondents after x minutes, if Puzzel is terminated, or we don't get an end call.
     */
    delayNewCall?: string
    /**
     * The delay before an ended call is added as a respondent.
     */
    delayEndCall?: string
    /**
     * The delay before an ended chat is added as a respondent.
     */
    delayEndChat?: string
    /**
     * Whether the employee should have a pGap after a call/chat.
     */
    shouldHavePGap: boolean
    
    setCampaignMemberModel: React.Dispatch<React.SetStateAction<any>>
    /**
     * Sets whether the employee should have a pGap after a call/chat.
     */
    setShouldHavePGap: React.Dispatch<React.SetStateAction<boolean>>
    /**
     * Sets the current state of Puzzel.
     */
    setPuzzelState: React.Dispatch<React.SetStateAction<PuzzelState>>
    setWidgetControls: React.Dispatch<React.SetStateAction<WidgetControls>>
}

export type WidgetControls = {
    /**
     * Weither the widget should be hidden or not. Sending surveys should still work.
     */
    hideWidget: boolean
}

const initialEmployeeState: EmployeeState = {
    userId: null,
    email: null,
    group: null
}

const initialPuzzelStatePuzzelState = {
    state: null,
    object: null,
    type: null,
    formattedNumber: null,
    queueKey: null,
    sessionId: null
}

const initialWidgetControls: WidgetControls = { 
    hideWidget: false
}

const initialState: EmployeeContextProps = {
    puzzelState: initialPuzzelStatePuzzelState,
    employeeState: initialEmployeeState,
    widgetControls: initialWidgetControls,
    campaignMemberModel: undefined,
    shouldHavePGap: false,
    setShouldHavePGap: () => {
    },
    setPuzzelState: () => {
    },
    setWidgetControls: () => {
    },
    setCampaignMemberModel: () => {
    }
}

const PuzzelContext = createContext(initialState);

type PuzzelProviderProps = {
    children: ReactNode
}

function PuzzelProvider({children}: PuzzelProviderProps) {
    const {enqueueSnackbar} = useSnackbar()
    const [employeeState, setEmployeeState] = useState<EmployeeState>(initialEmployeeState);
    const [widgetControls, setWidgetControls] = useState<WidgetControls>(initialWidgetControls);
    const [accessToken, setAccessToken] = useState("");
    const [customerKey, setCustomerKey] = useState("");
    const [userGroupId, setUserGroupId] = useState("");
    const [puzzelState, setPuzzelState] = useState<PuzzelState>(initialPuzzelStatePuzzelState);
    const [chatVariableName, setChatVariableName] = useState("");
    const [contextReady, setContextReady] = useState(false);
    const [shouldHavePGap, setShouldHavePGap] = useState(false);
    const [delayNewCall, setDelayNewCall] = useState(undefined);
    const [delayEndCall, setDelayEndCall] = useState(undefined);
    const [delayEndChat, setDelayEndChat] = useState(undefined);
    const [campaignMemberModel, setCampaignMemberModel] = useState<any>(undefined);

    useEffect(() => {
        const fetchEmail = async () => {
            if (employeeState?.email) return;
            if (employeeState?.userId && customerKey && accessToken) {
                const res = await axiosPuzzelInstance.get(`/${customerKey}/users/${employeeState.userId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                setEmployeeState((prevState) => ({
                    ...prevState,
                    email: res.data.result.eMail
                }))
            }
        }

        const fetchGroup = async () => {
            if (employeeState?.group) return;
            if (accessToken && employeeState?.userId && customerKey && userGroupId) {
                const res = await axiosPuzzelInstance.get(`/${customerKey}/usergroups`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                res.data.result.forEach((group) => {
                    if (group.id === userGroupId) {
                        setEmployeeState((prevState) => ({
                            ...prevState,
                            group: group.name
                        }))
                    }
                })
            }
        }

        fetchEmail().catch(console.error);
        fetchGroup().catch(console.error);
    }, [accessToken, employeeState, customerKey, userGroupId])

    useEffect(() => {
        if (!puzzelState.object) return;
        let caller: string;
        if ("messages" in puzzelState.object) {
            caller = puzzelState.object.variables.find(v => v.name === chatVariableName).value
        } else {
            caller = puzzelState.object.caller;
        }

        let customerNumber: string | null;

        if (!phone(caller, {country: 'DK'}).isValid) {
            if (!phone(caller.replace(/0{2}/, '+')).isValid) {
                customerNumber = caller; // Call is not danish/ is hidden
            } else {
                customerNumber = phone(caller.replace(/0{2}/, '+')).phoneNumber;
            }
        } else {
            customerNumber = phone(caller, {country: 'DK'}).phoneNumber;
        }
        
        setPuzzelState((prevState) => ({
            ...prevState,
            state: "incoming",
            formattedNumber: customerNumber
        }));

    }, [chatVariableName, puzzelState.sessionId, puzzelState.state, puzzelState.type, puzzelState.object])

    useEffect(()=>{
        if (campaignMemberModel) {
            console.log("payload from ended session was received / pgap dialog has been addressed.");
            axiosNpsInstance.post('/campaigns/' + campaignMemberModel?.campaignId + "/respondent?checkPgap=true", campaignMemberModel)
            .then((r: any)=>{
                setShouldHavePGap(r?.pgap);
            });
        }
    }, [campaignMemberModel])

    useEffect(() => {
        connect()
            .then(api => {
                api.on('SYSTEM_CHAT_STATUS_CHANGE', async ({chat}) => {
                    if (chat.status === 'Connected') {
                        setPuzzelState({
                            state: "idle", // Waiting for number to be processed
                            object: chat,
                            type: "chat",
                            formattedNumber: null,
                            queueKey: chat.queueKey,
                            sessionId: chat.sessionId
                        })
                    } else if (chat.status === 'Finished') {
                        setPuzzelState((prevState) => ({
                            ...prevState,
                            object: chat,
                            state: "ended",
                        }));
                    } else {
                        setPuzzelState((prevState) => ({
                            ...prevState,
                            object: chat,
                            state: "idle",
                        }));
                    }
                })
                api.on('SYSTEM_INCOMING_CALL', async ({call}) => {
                    setPuzzelState({
                        state: "idle", // Waiting for number to be processed
                        object: call,
                        type: "call",
                        formattedNumber: null,
                        queueKey: call.queueKey,
                        sessionId: call.sessionId,
                    })
                    setShouldHavePGap(false); // Reset pGap
                })
                api.on('SYSTEM_CALLOUT_CALL', async ({call}) => {
                    setPuzzelState({
                        state: "idle", // Waiting for number to be processed
                        object: call,
                        type: "call",
                        formattedNumber: null,
                        queueKey: call.queueKey,
                        sessionId: call.sessionId,
                    })
                    setShouldHavePGap(false); // Reset pGap
                })
                api.on('SYSTEM_CALL_PROGRESS_CHANGE', async ({call}) => {
                    if (!call.isActive) {
                        setPuzzelState((prevState) => ({
                            ...prevState,
                            object: call,
                            state: "ended",
                        }));
                    }
                })
                api.get('auth.getAccessToken').then((r) => {
                    setAccessToken(r)
                }).catch(() => {
                    enqueueSnackbar("Error fetching access token, make sure the widget is allowed to use access token", {variant: "error"})
                })
                api.get('auth.userId').then((r) => {

                    setEmployeeState((prevState) => ({
                        ...prevState,
                        userId: r.toString()
                    }))

                    // SignalR connection
                    const connection = new HubConnectionBuilder()
                    .withAutomaticReconnect()
                    .withUrl(axiosNpsWorkersInstance.defaults.baseURL + '?userid=' + r.toString()) 
                    .build();

                    connection.start()
                    .then(() => console.log('Connected to SignalR hub'))
                    .catch(err => console.error('Error connecting to hub:', err));

                    connection.on('sessionEnded', message => {
                        console.log("received message: " + message);
                        setCampaignMemberModel(JSON.parse(message));
                    });
                }).catch(() => {
                    enqueueSnackbar("Error fetching User ID", {variant: "error"})
                })
                api.get('auth.customerKey').then((r) => {
                    setCustomerKey(r)
                }).catch(() => {
                    enqueueSnackbar("Error fetching Customer Key", {variant: "error"})
                })
                api.get('auth.userGroupId').then((r) => {
                    setUserGroupId(r)
                }).catch(() => {
                    enqueueSnackbar("Error fetching User Group ID", {variant: "error"})
                })
                api.get('widget.options.APIkey').then((r) => {
                    axios.defaults.headers.common['authorization'] = `apikey ${r}`;
                    axiosNpsWorkersInstance.defaults.headers.common['authorization'] = `apikey ${r}`;
                }).catch(() => {
                    enqueueSnackbar("Error fetching API key, make sure it is set in widget settings", {variant: "error"})
                })
                api.get('widget.options').then((r) => {
                    setDelayNewCall(r.delayNewCall);
                    setDelayEndCall(r.delayEndCall);
                    setDelayEndChat(r.delayEndChat);
                    setWidgetControls(prevControls => ({
                        ...prevControls,
                        hideWidget: r.hideWidget ?? false
                      }));
                });
                api.get('widget.options.chatVariable').then((r) => {
                    setChatVariableName(r);
                }).catch(() => {
                    enqueueSnackbar("Error fetching chatVariable, make sure it is set in widget settings", {variant: "error"})
                })


                api.ready(); // Ready to receive events
            }).then(() => {
            setContextReady(true);
        })
            .catch(error => {
                console.log(error)
            });

    }, [enqueueSnackbar])

    return (
        <PuzzelContext.Provider
            value={{
                employeeState,
                puzzelState,
                campaignMemberModel,
                setPuzzelState,
                delayEndCall,
                delayEndChat,
                delayNewCall,
                chatVariableName,
                shouldHavePGap,
                setShouldHavePGap,
                setWidgetControls,
                widgetControls,
                setCampaignMemberModel
            }}>
            {contextReady ? children : <LinearProgress/>}
        </PuzzelContext.Provider>
    )
}

const useEmployeeContext = () => useContext(PuzzelContext);

export {PuzzelProvider, useEmployeeContext};